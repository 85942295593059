	/*
  	Flaticon icon font: Flaticon
  	Creation date: 02/10/2018 08:29
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticond41d.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-send:before { content: "\f100"; }
.flaticon-phone-call:before { content: "\f101"; }
.flaticon-phone-receiver:before { content: "\f102"; }
.flaticon-mail-black-envelope-symbol:before { content: "\f103"; }
.flaticon-facebook-logo:before { content: "\f104"; }
.flaticon-twitter:before { content: "\f105"; }
.flaticon-google-plus-logo:before { content: "\f106"; }
.flaticon-linkedin-logo:before { content: "\f107"; }
.flaticon-reward:before { content: "\f108"; }
.flaticon-enterprise:before { content: "\f109"; }
.flaticon-certificate:before { content: "\f10a"; }
.flaticon-working-team:before { content: "\f10b"; }
.flaticon-web-design:before { content: "\f10c"; }
.flaticon-computer-graphic:before { content: "\f10d"; }
.flaticon-servers:before { content: "\f10e"; }
.flaticon-digital-marketing:before { content: "\f10f"; }
.flaticon-link:before { content: "\f110"; }
.flaticon-happiness:before { content: "\f111"; }
.flaticon-coffee-cup:before { content: "\f112"; }
.flaticon-like:before { content: "\f113"; }
.flaticon-building:before { content: "\f114"; }
.flaticon-avatar:before { content: "\f115"; }
.flaticon-facebook-placeholder-for-locate-places-on-maps:before { content: "\f116"; }
.flaticon-share:before { content: "\f117"; }
.flaticon-instagram:before { content: "\f118"; }
.flaticon-magnifying-glass:before { content: "\f119"; }
.flaticon-group-meeting:before { content: "\f11a"; }
.flaticon-chat:before { content: "\f11b"; }
.flaticon-search:before { content: "\f11c"; }
.flaticon-plane:before { content: "\f11d"; }
.flaticon-calendar:before { content: "\f11e"; }
.flaticon-price:before { content: "\f11f"; }
.flaticon-check:before { content: "\f120"; }
.flaticon-folder:before { content: "\f121"; }
.flaticon-pin:before { content: "\f122"; }
.flaticon-arrow:before { content: "\f123"; }
.flaticon-expand-button:before { content: "\f124"; }
.flaticon-earth:before { content: "\f125"; }
.flaticon-back:before { content: "\f126"; }
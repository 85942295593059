h1 {
    font-family: 'Roboto Slab', serif;
    font-size: 2em;
    text-indent: 40px;
    padding-top: 30px;
  }
  
  h4 {
    font-family: 'Roboto Slab', serif;
    margin: 0;
    font-size: 1.5em;
  }
  
  h5, .fig {
    font-family: 'Didact Gothic', sans-serif;
    margin: 0;
    font-size: 1em;
  }
  
  figure {
    width: 480px;
    height: 300px;
    position: relative;
    box-shadow: 2px 2px 5px black;
    border-radius: 20px;
    overflow: hidden;
    display: inline-block;
  }
  
  figcaption {
    width: 100%;
    height: 80px;
    background-color: rgba(255,255,255,.8);
    position: absolute;
    bottom: 0px;
    padding: 10px 0px 0px 20px;
    box-sizing: border-box;
    overflow: hidden;
    transition: 300ms ease-in-out;
  }
  
  figcaption:hover {
    height: 100%;
    background-color: rgba(255,255,255,1);
  }
  
  img.ie {
    /*max-width:100%;
    max-height:100%;*/
     width: 480px;
    height: 300px;  
  }
  
  .fig {
    padding: 5px;
    margin-top: 10px;
  }
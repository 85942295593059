


@media only screen and (max-width: 1400px) {



     .slider.index-2 .caption {
         text-align: left;
         left: 10%;
         top: 110px;
         width: 550px;
         height: auto;
         padding-top: 35px;
    }
}
 


 @media only screen and (max-width: 1280px) {



     .slider.index-2 .caption {
         height: auto;
         top: 13%;
         left: 7%;
    }
     .wraper section#qualities.index-3{
         position: relative;
         top:0;
         padding:100px 0;
    }
}
 


 @media only screen and (max-width: 1199.98px) {



     .row{
         overflow-y:hidden;
    }
     #services .owl-item.active{
         margin-right: 27px !important;
    }
     section#counter .counter-column .count_icon {
         float: left;
         display: inline-block;
         width: 25%;
    }
     section#counter .counter-column .count_icon i.flaticon-happiness::before{
         font-size: 60px;
    }
     section#counter .counter-column .count_icon i.flaticon-coffee-cup::before, section#counter .counter-column .count_icon i.flaticon-building::before, section#counter .counter-column .count_icon i.flaticon-like::before{
         font-size: 55px;
    }
     section#counter .counter_content{
         float: left;
         margin-left: 15px;
    }
     section#counter .counter-column .count_icon {
         float: left;
         display: inline-block;
         width:auto;
    }
     section#counter .counter-column .counter_content{
         float: left;
         margin-left: 20px;
         width: 60%;
    }
     section#blog_post .blog-slider .post-detail {
         border:solid 1px #e6e6e6;
         padding:9px 18px;
         float: left;
         width: 50%;
    }
     section#blog_post .blog_card{
         display:inherit;
    }
     .slider.index-2 .caption {
         text-align: left;
         left:4%;
         top:120px;
         width:550px;
    }
     .slider.index-2 .caption .hello{
         font-size:20px;
         font-weight:600;
         font-family: 'Poppins', sans-serif;
         position: relative;
         padding-left:90px;
         height:auto;
         margin-bottom:15px;
    }
     .slider.index-2 .caption .hello::before{
         content:url(../img/hello.png);
         position: absolute;
         left: 0;
         background-size:50px auto;
         top:-35px;
    }
     section#blog_post.index-2 .blog-slider .post-detail{
         border:none;
         padding:0;
         padding-top: 20px;
    }
     section#client.index-2 .left-tri{
         display:none;
    }
     section#client.index-2 .right-tri{
         display:none;
    }
     section#client.index-2 .team-slider{
         background:#fff;
         padding:30px;
         position: relative;
         border: solid 1px #e4e4e4;
    }
     section#blog_post .blog-slider .post-img {
         position: relative;
         padding: 0px;
         float: left;
         width: 100%;
         overflow-x: hidden;
    }
     section#blog_post .blog-slider .post-detail {
         border:solid 1px #e6e6e6;
         padding:12px 18px;
         float: left;
         width: 100%;
         min-height:160px;
    }
}
 


 @media only screen and (max-width: 1140px) {



     #slide-3238-layer-12, #slide-3238-layer-11, #slide-3238-layer-10, #slide-3238-layer-9 {
         display:none;
    }
     section#services.index-2 .h-border {
         width:97%;
    }
     section#contact-us .info ul li .content{
         width:78%;
    }
     section#blog-with-sidebar .posts .post-img {
         width: 40%;
         height:auto;
         overflow:hidden;
    }
     section#blog-with-sidebar .posts .post-detail{
         width:60%;
    }
     section#blog-with-sidebar .posts .post-img img{
         object-fit: cover;
    }
     section#client.index-2 .left-tri{
         width: 50px;
         height: 40px;
         border-style: solid;
         border-width: 0 25px 45px 0;
         border-color: transparent #e0e0e0 transparent transparent;
         position: absolute;
         left: -35px;
         top: 86px;
    }
     section#client.index-2 .right-tri{
         width: 50px;
         height: 40px;
         border-style: solid;
         border-width:25px 45px 0 0;
         border-color:#e0e0e0 transparent transparent transparent;
         position: absolute;
         right: -35px;
         top: 86px;
    }
}
 


 @media only screen and (max-width: 1024px) {



     .row{
         overflow-x:hidden;
    }
     .owl-nav{
         display:none !important;
    }
     #slider #slide-3238-layer-1.tp-caption.heading, #slider #slide-3238-layer-15.tp-caption.heading, #slider #slide-3238-layer-16.tp-caption.heading {
         font-size:60px !important;
         color:#262626;
         top:200px;
    }
     #slider #slide-3238-layer-1.tp-caption.heading span, #slider #slide-3238-layer-15.tp-caption.heading span, #slider #slide-3238-layer-16.tp-caption.heading span {
         font-size:60px !important;
    }
     #slider #slide-3238-layer-2.tp-caption.content{
         font-size:18px !important;
         width:600px !important;
         line-height:25px !important;
         margin:30px auto 40px;
         display:inline-table;
         color:#262626;
         white-space:normal;
         top:270px;
         text-align: center !important;
    }
     #slider #slide-3238-layer-3.tp-caption.buttons {
         top:390px;
    }
     .rev_slider img{
         transform: scale(0.8) !important;
        /* Equal to scaleX(0.7) scaleY(0.7) */
    }
     section#counter .counter-column .count_icon i.flaticon-happiness::before{
         font-size: 65px;
    }
     section#counter .counter-column .count_icon i.flaticon-coffee-cup::before, section#counter .counter-column .count_icon i.flaticon-building::before, section#counter .counter-column .count_icon i.flaticon-like::before{
         font-size: 60px;
    }
     section#counter .counter_content{
         float: left;
         margin-left: 15px;
    }
     section#testimonials .testimonials-slider .item{
         float:left;
         text-align: center;
         padding:10px 40px 30px 40px;
         background: #fff;
         position: relative;
    }
     section#testimonials .testimonials-slider .owl-item {
         height:auto;
         padding:15px 0 35px;
    }
     section#counter .counter-column{
         margin:20px 0;
    }
     section#about-us.index-2 .content-area {
         top:2%;
    }
     section#quick-connect.index-2 .newsletter form input[type="text"] {
         width:63%;
    }
     footer.index-2 .contact-info ul li .detail{
         width:75%;
    }
     .slider.index-2 .caption {
         text-align: left;
         left:4%;
         top:150px;
         width:550px;
    }
     footer.index-2 .contact_info ul li .detail{
         width:69%;
    }
     .slider.index-2 .owl-main .owl-dots {
         left:95%;
    }
     section#member .meeting_content {
         width:79%;
    }
     section#member .heading h3{
         font-size:24px;
    }
     section#member .meeting_icon {
         height: auto;
         width: 15%;
         position: relative;
         padding: 5px 0px 5px 10px;
         margin-left: 40px;
         float: left;
    }
     section#member .heading h3 span {
         font-size:30px;
    }
     section#quick-connect.index-3 .newsletter {
         padding: 30px;
    }
     section#quick-connect.index-3 .newsletter form button[type="submit"] {
         padding:10px 14px;
    }
     section#quick-connect.index-3 .quick-info .content .contact-no {
         font-size:33px;
    }

    section#contact-us .info ul li .content{
         width:75%;
    }
}
 


 @media only screen and (max-width: 990px){

     section#quick-connect.index-2 .newsletter form input[type="text"] {
         width:60%;
    }


     .navbar-collapse {
         margin:20px 0;
    }
     .navbar-nav .nav-item a, .navbar-nav .dropdown-item a, .navbar-expand-lg .navbar-nav .nav-link {
         color:#fff;
    }
     .dropdown-toggle::after {
         float:right;
         color:#fff;
    }
     section .section_heading .heading_content{
         width:90%;
    }
     #slider .slider-content p{
         font-size: 14px !important;
         margin:20px auto !important;
    }
     #slider .rev_slider img{
         transform: scale(1);
        /* Equal to scaleX(0.7) scaleY(0.7) */
    }
     #about-detail .mid-content .content_sec {
         margin-top: 20px;
    }
     section#counter .counter-column .counter_content{
         float: left;
         margin-left: 20px;
         width: 57%;
    }
     section#counter .counter-column{
         margin:20px 0;
         padding-left:10%;
         width: 50% 
    }
     .sidebar .widget .widget-content .post-item .post-img{
         width:auto;
         margin-top:0px;
         float: left;
         overflow-x: hidden;
    }
     .sidebar .widget .widget-content .post-item .post-img img{
         margin-top:5px;
    }
     section#portfolio .project:hover .label {
         bottom:0;
    }
     section#blog_post .blog-slider .post-detail {
         border:solid 1px #e6e6e6;
         padding:12px 18px;
         float: left;
         width: 100%;
         min-height:155px;
    }
     section#testimonials .testimonials-slider .item{
         float:left;
         text-align: center;
         padding:0px 40px 30px 40px;
         background: #fff;
         position: relative;
    }
     section#testimonials .testimonials-slider .item::before{
         content:url('../img/testimonial_quote.png');
         position: relative;
         top: -15px 
    }
     footer .newsletter form input{
         margin-top: 15px;
    }
     footer .newsletter h4 {
         padding-top: 0px;
    }
     footer .footer_about_content{
         margin-bottom: 20px;
    }
     section#testimonials.index-2 .testimonials-slider .owl-item {
         height:auto;
         padding:15px 0 0;
    }
     section#about-us.index-2 .row.row-safari{
         display:inline-block;
    }
     section#about-us.index-2 .content_sec{
         position: relative;
         width:auto;
         margin-left:15px;
         margin-right:15px;
    }
     section#about-us.index-2 .img-area {
         position: relative;
         border-radius:20px 20px 0 0;
         padding: 0;
         max-width:100%;
    }
     section#about-us.index-2 .img-area::before{
         content:'';
         border-radius:20px 20px 0 0;
         border: solid 1px rgba(255, 255, 255, 0.5);
         width:90%;
         height:85%;
         margin:5%;
         position: absolute;
         z-index: 1;
    }
     section#about-us.index-2 .img-area img{
         border-radius:20px 20px 0 0;
    }
     section#about-us.index-2 .content-area {
         position: relative;
         background:#fff;
         padding:19px 35px;
         border:solid 1px #c9c9c9;
         border-radius:0px 0px 20px 20px;
         right: 0;
         top:0;
         box-shadow:none;
         z-index: 1;
         max-width:100%;
         margin-top: 0;
    }
     section#member .meeting{
         width: 100%;
         float: none;
         margin: 0 auto 
    }
     section#member .heading{
         max-width:50%;
         margin:0px auto;
         flex:none;
         text-align:center;
         display:table;
         line-height: 1;
    }
     section#member .heading h3{
         display:inline;
    }
     section#member .heading h3 span {
         display:inline;
         font-size:30px;
         display:inline-table;
    }
     section#member .meeting_content {
         width:100%;
         background: none;
         text-align:center;
         margin:0px auto;
         padding-bottom: 0;
         padding-top: 10px;
         color:#fff;
    }
     section#member .meeting_content::before {
         background:none;
         border:none;
    }
     section#member .meeting_icon {
         height:100px;
         width:100px;
         position: relative;
         border-radius:50%;
         padding: 5px 0px 5px 10px;
         margin:30px auto 10px;
         float:none;
         border:none;
         text-align: center;
         padding: 0;
    }
     section#member .meeting_icon::before {
         background:none;
         border:none;
    }
     section#member .heading .q-mark {
         font-size:27px;
         font-weight: 700;
         font-family: 'Poppins', sans-serif;
         display:inline;
         float:none;
         line-height:normal;
    }
     section#our_team.index-2 .team-card{
         width:100%;
         margin-bottom: 20px;
    }
     section#member .meeting_content a{
         font-weight: 700;
         color: #fff;
    }
     section#our_team {
         padding-bottom: 60px;
    }
     section#our_team.index-2 .item {
         width:100%;
         margin-bottom: 40px;
    }
     footer.index-3 .contact-info .column{
         margin-bottom: 25px;
    }
     section#quick-connect.index-3 .newsletter, section#quick-connect.index-3 .quick-info {
         min-height:299px;
    }
     section#quick-connect.index-2 .newsletter form input[type="text"] {
         width:60%;
    }
     footer .newsletter h4{
         line-height:54px;
    }
     .slider.index-3 .caption {
         width:600px;
         left:11%;
    }
     section#about-us.index-3 .img-area::after {
         content:none;
    }
     section#about-detail .content-area{
         margin-top:0px;
    }
     section#qualities .content .column{
         width:50%;
         float:left;
         border:solid 1px #e6e6e6;
    }
     section#qualities.index-3 .content{
         display: inline-block;
         padding: 0;
    }
}
 


 @media only screen and (max-width: 767px){



     #slider #slide-3238-layer-1.tp-caption.heading, #slider #slide-3238-layer-15.tp-caption.heading, #slider #slide-3238-layer-16.tp-caption.heading {
         font-size:60px !important;
         color:#262626;
         top:240px;
    }
     section#about-detail.index-2 .content-area{
         margin-top:20px;
    }
     section#about-detail.index-2 .mid-content{
         margin-bottom:80px;
    }
     section#about-detail.index-3 .mid-content > div > div{
         margin-bottom:60px;
    }
     section#about-detail.index-3 .mid-content > div > div:last-child{
         margin-bottom:0px;
    }
     section#about-detail.index-3 .mid-content .separator.left-align{
         margin:0px;
    }
     section#about-detail.index-2 .mid-content:last-child{
         margin-bottom:0px;
    }
     #slider #slide-3238-layer-1.tp-caption.heading span, #slider #slide-3238-layer-15.tp-caption.heading span, #slider #slide-3238-layer-16.tp-caption.heading span {
         font-size:60px !important;
    }
     #slider #slide-3238-layer-2.tp-caption.content{
         font-size:18px !important;
         width:600px !important;
         line-height:25px !important;
         margin:30px auto 40px;
         display:inline-table;
         color:#262626;
         white-space:normal;
         top:310px;
         text-align: center !important;
    }
     #slider #slide-3238-layer-3.tp-caption.buttons {
         top:430px;
    }
     .rev_slider img{
         transform: scale(0.8) !important;
        /* Equal to scaleX(0.7) scaleY(0.7) */
    }
     #slide-3238-layer-8, #slide-3238-layer-12, #slide-3238-layer-11, #slide-3238-layer-10, #slide-3238-layer-9 {
         display:none;
    }
     section#about-us .content_sec .about_content{
         margin-top:40px;
    }
     section#about-us .content_sec .about_content p:last-child{
         display: none;
    }
     section#portfolio #myTab .tab-content{
         border:none;
         padding-top: 40px;
         clear:both;
    }
     footer .newsletter form input[type="text"] {
         margin-top:15px;
    }
     footer .newsletter form button[type="submit"]{
         margin-top:15px;
    }
     section#pricing .content_sec {
         margin:60px auto 0;
    }
     section#pricing .content_sec .price_card{
         margin-bottom:30px;
    }
     section#pricing .content_sec .price_card:last-child{
         margin-bottom:0;
    }
     section#single-post-detail .comments ol.comments-list.reply {
         margin:0px;
         width: 100%;
    }
     section#testimonials .testimonials-slider .item{
         float:left;
         text-align: center;
         padding:0px 40px 0px 40px;
         background: #fff;
         position: relative;
    }
     .content-area {
         margin-top: 40px;
         padding-left: 15px;
    }
     section#single-post-detail .tags_share .tags {
         width:100%;
    }
     section#single-post-detail .tags_share .share_on {
         width:100%;
         margin-top: 10px;
    }
     section#blog-with-sidebar .blog_card .post-img {
         width: 100%;
    }
     section#blog-with-sidebar .blog_card .post-img img {
         width: 100%;
    }
     section#blog-with-sidebar .blog_card .post-detail {
         width:100%;
         border:solid 1px #e6e6e6 !important;
         border-top:none;
    }
     section#blog-with-sidebar .post-img {
         width:auto;
    }
     .sidebar.left{
         margin-bottom: 50px;
         margin-top: 0px;
    }
     .sidebar.right{
         margin-top: 50px;
         margin-bottom:0;
    }
     .pagination{
         justify-content: center !important;
    }
     section#page-not-found .errow-heading::before {
         line-height: 55px;
         font-size: 50px;
         top: 5%;
         left:150px;
    }
     section#page-not-found .errow-heading img {
         z-index:2;
         position: relative;
    }
     section#page-not-found .errow-heading::after {
         line-height: 55px;
         font-size: 50px;
         top: 5%;
         right:150px;
    }
     section#page-not-found .error-content {
         padding:0px;
    }
     section#page-not-found {
         padding:100px 45px !important;
    }
     section#about-us.index-3 .img-area{
         padding:0 15px;
    }
     section#about-us.index-3 .img-area img{
         margin:0px;
    }
     section#about-us.index-3 .content-area{
         padding:0 15px;
    }
     section#portfolio .sort-btn {
         margin-bottom:10px;
    }
     section#qualities.index-3 .content{
         display:inline-block;
         border:none;
         padding: 0;
    }
     section#qualities .content .column:last-child{
         border:solid 1px #e6e6e6;
    }
     section#testimonials.index-2::after, section#counter.index-2::after{
         display:none;
    }
     section#counter.index-2 .counter-column{
         width:50%;
    }
     section#testimonials.index-2 .testimonials-slider .item .client_detail {
         text-align: left;
         margin-top: 24px;
         padding-left: 60px;
         display: inline-block;
         width: 100%;
    }
     .slider.index-2 .owl-main .caption .content {
         font-size:16px;
    }
     .slider.index-2 .owl-main .owl-dots {
         text-align: center;
         width:100%;
         left:0;
         position:absolute;
         z-index:999;
         margin:0 auto;
         top:90%;
    }
     .slider.index-2 .owl-main .owl-dots button.owl-dot {
         display:inline;
         margin:0 10px;
    }
     .slider.index-2 .owl-main .owl-dots button.owl-dot span{
         display:inline-block;
         padding:0px;
         margin:0 5px;
         height:10px;
         width:10px;
         background: #fff;
    }
     .slider.index-2 .owl-main .owl-dots button.owl-dot.active span{
         height:10px;
         width:20px;
         transition: all 0.3s ease;
         margin:0px;
         background:#ff6204;
    }
     footer .bottom .copyright, footer .bottom .text-right{
         text-align:center !important;
         margin-top: 20px;
    }
     footer .copyright > div > div{
         margin:5px 0;
         width:100%;
         text-align: center !important;
         float:none;
    }
     section#member .heading{
         max-width: 100%;
    }
     section#member .meeting{
         display: contents;
    }
     section#qualities.index-2 {
         display: inline-block;
    }
     section#qualities.index-3 .content .column:last-child{
         border-right:solid 1px #e6e6e6;
    }
     footer.index-3{
         background-size:cover;
    }
     footer.index-3 .about{
         border-right:none;
         margin-bottom:20px;
    }
     footer.index-3 .latest-news {
         padding-left:15px;
         margin-top: 20px;
    }
     footer.index-3 .latest-news .post-item .post-img{
         margin-right: 15px;
         width:70px;
         height:70px;
    }
     footer.index-3 .latest-news .post-item .post-img img{
         width:100%;
    }
     footer.index-3 .latest-news .post-item .post-content {
         margin-left: 0;
    }
     footer.index-3 .contact-info .icon{
         float: left;
         margin-right: 15px;
         width:70px;
         height:70px;
    }
     footer.index-3 .contact-info .detail{
         text-align: left;
         width:80%;
         margin-top:0px;
    }
     footer.index-3 .contact-info .detail .heading{
         line-height:1;
    }
     section#quick-connect.index-3 .newsletter, section#quick-connect.index-3 .quick-info {
         min-height:250px;
         display:inline-table;
    }
     section#blog_post .blog-slider .post-detail {
         min-height:145px;
    }
     .header-top.contact_info .follow_us_on{
         margin-bottom: 10px;
    }
     .header-top.contact_info .follow_us_on, .header-top.contact_info .mail-call{
         border-top:none;
         text-align: center;
    }
     .slider.index-3 .caption {
         width:550px;
         left:11%;
    }
     section#portfolio .sort-btn {
         background: #f4f4f4;
         font-size: 14px;
         color: #262626;
         padding:10px 20px;
         border:none;
         border-radius:0px;
         margin:0px 5px;
         display:inline-block;
    }
     section#portfolio .sort-btn.active, section#portfolio .sort-btn:hover{
        padding:9px 19px;
    }
     footer.index-3 .contact_info .detail .content{
         display:inline-block;
    }
     .team_member_detail .counter-column{
         width:50%;
         margin:0px;
    }
     .team_member_detail .counter-column .counter_content{
         margin:0px;
    }
     section#team_member_detail .contact-detail li{
         margin-bottom:15px;
    }
     section#single-post-detail .comments ol li .visitor_pic{
         width:19%;
    }
     section#single-post-detail .comments ol li .visitor_description {
         width:74%;
         float: left;
    }
}
 


 @media only screen and (max-width: 640px){



     #slider #slide-3238-layer-1.tp-caption.heading, #slider #slide-3238-layer-15.tp-caption.heading, #slider #slide-3238-layer-16.tp-caption.heading {
         font-size:50px !important;
         color:#262626;
         top:240px;
    }
     #slider #slide-3238-layer-1.tp-caption.heading span, #slider #slide-3238-layer-15.tp-caption.heading span, #slider #slide-3238-layer-16.tp-caption.heading span {
         font-size:50px !important;
    }
     #slider #slide-3238-layer-2.tp-caption.content{
         font-size:18px !important;
         width:600px !important;
         line-height:25px !important;
         margin:30px auto 40px;
         display:inline-table;
         color:#262626;
         white-space:normal;
         top:310px;
         text-align: center !important;
    }
     #slider #slide-3238-layer-3.tp-caption.buttons {
         top:430px;
    }
     #slide-3238-layer-13, #slide-3238-layer-8, #slide-3238-layer-12, #slide-3238-layer-11, #slide-3238-layer-10, #slide-3238-layer-9 {
         display:none;
    }
     section#counter .counter-column{
         width:50%;
         margin:20px 0;
         padding-left: 5%;
    }
     section#portfolio #myTab .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
         padding:10px 16px;
    }
     section .section_heading .heading_content p{
         font-size:16px;
    }
     section#testimonials .testimonials-slider .item{
         float:left;
         text-align: center;
         padding:0px 40px 30px 40px;
         background: #fff;
         position: relative;
    }
     section#testimonials .testimonials-slider .owl-item {
         height:auto;
         padding:15px 0 35px;
    }
     #pills-tabContent{
         padding-top:0px !important;
    }
     .index-2 .img-area::before {
         width:80%;
    }
     .index-3 .header-top.contact_info .mail-call, .index-2 .header-top.contact_info .mail-call, .separator-pipesign {
         margin:-2px 9px;
    }
     .slider.index-3 .owl-main{
         overflow:hidden;
         height:700px;
    }
     .slider.index-3 .caption {
         text-align:center;
         margin:0px auto;
         width:450px;
         height:auto;
         top:130px;
         position: absolute;
         right: 15%;
         bottom: 20px;
         left: 15%;
         z-index: 10;
         padding-top: 20px;
         padding-bottom: 20px;
         color: #fff;
    }
     .slider.index-3 .caption .content{
         font-size:16px;
    }
     .slider.index-3 .caption .display{
         font-size:50px;
         line-height:60px;
         margin:10px 0;
         font-family: 'Poppins', sans-serif;
         font-weight: 700;
    }
     section#counter .counter-column .count_icon{
         width:50px;
    }
     section#counter .counter-column .count_icon i.flaticon-coffee-cup::before, section#counter .counter-column .count_icon i.flaticon-building::before, section#counter .counter-column .count_icon i.flaticon-like::before{
         font-size: 55px;
    }
     section#team_member_detail .member-pic{
         margin-bottom:50px;
    }
     section#blog_post.index-2 .post-detail .post-status ul li{
         display:inline-block;
         float:none;
         text-align: center;
    }
}
 


 @media only screen and (max-width: 568px){



     #slider .tp-caption.content{
         display:none;
    }
     section#blog-with-sidebar .posts .blog_card.d-flex{
         display:inline-block !important;
    }
     section#blog-with-sidebar .posts .post-img {
         width: 100%;
         height:auto;
         overflow:hidden;
    }
     section#blog-with-sidebar .posts .post-detail{
         width:100%;
    }
     #slider #slide-3238-layer-2.tp-caption.content{
         font-size:18px !important;
         width:490px !important;
         line-height:25px !important;
         margin:30px auto 40px;
         display:inline-table;
         color:#262626;
         white-space:normal;
         top:310px;
         text-align: center !important;
    }
     .rev_slider img{
         transform: scale(1) !important;
        /* Equal to scaleX(0.7) scaleY(0.7) */
    }
     .section_heading h2{
         font-size: 30px;
         color:#262626;
         font-weight: 500;
    }
     .section_heading h1{
         font-size: 30px;
    }
     .top-content h5{
         margin-bottom:20px;
    }
     section#services.index-3 .service-card .service_icon {
         position: absolute;
         top:-35px;
         left:42%;
    }
     .section_heading .heading_content{
         font-size:16px;
         line-height: 26px;
    }
     section#qualities.index-3 .content .column{
         padding: 15px 20px;
    }
     .slider.index-2 .caption {
         width: auto;
         top:180px;
         left:5%;
         right:5%;
    }
     .slider.index-2 .caption .display{
         font-size:50px;
         line-height:60px;
    }
     section#quick-connect.index-2 .newsletter, section#quick-connect.index-2 .quick-info {
         padding:40px 20px 
    }
     section#quick-connect.index-2 .newsletter form {
         background:none;
         text-align: center;
    }
     section#quick-connect.index-2 .newsletter form input[type="text"] {
         width:100%;
         background:rgba(0, 0, 0, 0.2);
         border-radius:20px;
    }
     section#quick-connect.index-2 .newsletter form button[type="submit"] {
         width:auto;
         margin:10px auto;
         float:none;
    }
     footer.index-2 .contact-info ul li .detail{
         width:75%;
    }
     section#quick-connect.index-2 .quick-info .content .contact-no {
         font-size: 25px;
    }
     section#quick-connect.index-3 .newsletter form input[type="text"]{
         padding:10px 20px;
         border:none;
         margin:0px;
         background:none;
         border:none;
         font-size:14px;
         color:#fff;
         width:100%;
    }
     section#quick-connect.index-3 .newsletter form button[type="submit"]{
         border-radius:0;
         background:#262626;
         color:#fff;
         border:none;
         margin-top:10px;
         padding:10px 35px;
         float:right;
         font-size: 14px;
         width:100%;
    }
     section#testimonials.index-3{
         margin-top:270px;
    }
     footer.index-3 .contact_info .column{
         margin-bottom:50px;
    }
     footer.index-3 .contact_info .detail .content{
         display:inline-table;
    }
     section#about-us.index-2 .qualities .column{
         text-align: center;
    }
     section#about-us.index-2 .qualities .column .icon {
         width:100%;
         float:none;
         margin:0px auto;
    }
     section#about-us.index-2 .qualities .column .description{
         padding:0px;
    }
     .slider.index-3 .caption {
         text-align:center;
         margin:0px auto;
         width:450px;
         height:auto;
         top:150px;
    }
     .slider.index-3 .owl-main .owl-nav{
         display:none !important;
    }
     .slider.index-3 .caption {
         left: 10%;
    }
     .slider.index-3 .caption .content {
         padding:0 5%;
    }
     .sidebar .widget .widget-content .post-item{
         width:100%;
    }
}
 


 @media only screen and (max-width: 480px){



     .slider.index-3 .caption {
         text-align:center;
         margin:0px auto;
         width:400px;
         height:auto;
         top:170px;
         position: absolute;
         right:0;
         bottom: 20px;
         left:0;
         z-index: 10;
         padding-top: 20px;
         padding-bottom: 20px;
         color: #fff;
    }
     #slider #slide-3238-layer-2.tp-caption.content{
         display:none;
    }
     #slider #slide-3238-layer-3.tp-caption.buttons {
         top:335px;
    }
     section#counter .counter-column{
         width: 100%;
         margin: 20px auto;
         padding: 0;

    }
     section#single-post-detail .author_sec .author-pic {
         width:40%;
         margin: 0px auto;
         float: none;
    }
     section#single-post-detail .author_sec .about-author {
         width:100%;
         margin:20px auto 0;
         float: none;
    }
     section#single-post-detail .author_sec .about-author .name_designation, section#single-post-detail .author_sec .about-author .social_media, section#single-post-detail .author_sec .about-author .description{
         width:100%;
         margin:10px auto 0;
         text-align:center;
         float: none;
    }
     section#blog_post .blog-slider .post-detail {
         text-align: center;
    }
     .post-detail .post-status ul li{
         float:left;
         min-width:50px;
         color: #797979;
         margin-right: 25px;
         font-size: 14px;
    }
     section#services .service-slider .owl-item .item {
         text-align: center;
    }
     section#single-post-detail .comments ol li .visitor_description {
         width:68%;
         float: left;
    }
     section#testimonials .testimonials-slider .owl-item {
         height:auto;
         padding:15px 0 35px;
    }
     section#testimonials .testimonials-slider .item{
         float:left;
         text-align: center;
         padding:0px 40px 30px 40px;
         background: #fff;
         position: relative;
    }
     .content-area {
         margin-top:40px;
         padding-left:15px;
    }
     section#counter .counter-column .count_icon {
         float: left;
         display: inline-block;
         width:70px;
    }
     section#single-post-detail .tags_share .tags ul li {
         margin-top:5px;
    }
     footer .contact_info ul li{
         width: 100%;
    }
     footer .navigations{
         margin-bottom: 30px;
    }
     footer .navigations ul{
         margin-top: 20px;
    }
     section#services-counter .counter-column .counter_content {
         margin-left:0px;
    }
     section#portfolio #myTab .nav-tabs a.nav-item{
         display: inline-block !important;
         float:none;
         margin-bottom:10px;
         padding:10px 32px;
    }
     section#page-not-found .errow-heading::before {
         line-height: 55px;
         font-size:30px;
         top: 5%;
         left:65px;
    }
     section#page-not-found .errow-heading img {
         z-index:2;
         position: relative;
         width:auto;
         height:auto;
    }
     section#page-not-found .errow-heading::after {
         line-height: 55px;
         font-size:30px;
         top: 5%;
         right:60px;
    }
     section#page-not-found .error-content {
         padding:0px;
    }
     section#page-not-found {
         padding:100px 45px !important;
    }
     section#single-post-detail .blog-detail .separator.left-align {
         float: none;
         width:120px;
         margin:20px auto 0;
         float:none;
         text-align:center;
    }
     section#single-post-detail .blog-detail .separator.left-align ul::before, section#single-post-detail .blog-detail .separator.left-align ul::after {
         display:block;
         top:55%;
    }
     section#single-post-detail .comments h5, section#single-post-detail .reply_form h5 {
         text-align:center;
         width: 100%;
    }
     section#single-post-detail .comments ol li .visitor_pic{
         width:30%;
         float: none;
         margin: 0px auto;
         text-align: center;
    }
     section#single-post-detail .comments ol li .visitor_description {
         width:100%;
         padding:0px;
         margin-top: 20px;
         text-align:center;
    }
     section#single-post-detail .comments ol li .visitor_description h6{
         display:block;
         text-align:center;
         width: 100%;
    }
     section#single-post-detail .comments ol li .visitor_description .date{
         border:none;
         padding:0px;
         margin:5px 0;
    }
     section#qualities.index-3 .content{
         border:none;
    }
     section#qualities .content .column{
         width:100%;
         border:solid 1px #e6e6e6;
         border-bottom:none;
    }
     section#qualities .content .column:last-child{
         width:100%;
         border:solid 1px #e6e6e6;
    }
     section#blog_post.index-3 .blog-slider .post-detail{
         text-align: left;
         min-height:auto;
    }
     .slider.index-2 .caption .hello{
         height:35px;
    }
     .slider.index-2 .caption .display{
         font-size:50px;
         line-height:60px;
    }
     section#testimonials.index-2 .testimonials-slider .item .client_detail {
         padding-left:20px;
    }
     section#quick-connect.index-3 .quick-info .content .contact-no {
         font-size:22px;
    }
     section#counter.index-2{
         padding-bottom: 100px;
    }
     section#counter.index-2 .counter-column{
         width:50%;
         margin-bottom:0px;
    }
     section#counter.index-2 .counter-column .count_icon i.flaticon-happiness::before{
         font-size: 45px;
    }
     section#counter.index-2 .counter-column .count_icon i.flaticon-coffee-cup::before, section#counter.index-2 .counter-column .count_icon i.flaticon-building::before, section#counter.index-2 .counter-column .count_icon i.flaticon-like::before{
         font-size: 40px;
    }
     section#counter.index-2 .counter-column .separator {
         display:none;
    }
     section#counter.index-2 .counter-column .count_icon{
         float: left;
         margin-right:20px;
    }
     section#counter.index-2 .counter-column .counter_content{
         margin-top:0px;
    }
     section#contact-us .info ul li .content{
         width:75%;
    }
     section#contact-us .info ul li .content .value {
         font-size:16px;
    }
     .slider.index-3 .caption .display{
         font-size:40px;
         line-height:50px;
    }
}
 


 @media only screen and (max-width: 320px){



     .slider.index-2 .owl-main .caption{
         padding-top: 0;
    }
     footer.index-2 .contact_info ul li .detail{
         margin-top:20px;
         display:block;
         width:100%;
    }
     #slider #slide-3238-layer-1.tp-caption.heading, #slider #slide-3238-layer-15.tp-caption.heading, #slider #slide-3238-layer-16.tp-caption.heading {
         font-size:40px !important;
         color:#262626;
         top:150px;
    }
     #slider #slide-3238-layer-1.tp-caption.heading span, #slider #slide-3238-layer-15.tp-caption.heading span, #slider #slide-3238-layer-16.tp-caption.heading span {
         font-size:40px !important;
    }
     #slider #slide-3238-layer-3.tp-caption.buttons {
         top:220px;
    }
     #slider #slide-3238-layer-3.tp-caption.buttons a{
         margin:0px 5px;
    }
     .rev_slider img{
         transform: scale(0.7) !important;
        /* Equal to scaleX(0.7) scaleY(0.7) */
    }
     .slider.index-3 .caption {
         text-align:center;
         margin:0px auto;
         width:320px;
         height:auto;
         top:130px;
         position: absolute;
         right:0;
         bottom: 20px;
         left:0;
         z-index: 10;
         color: #fff;
    }
     section#single-post-detail .sidebar .widget h5{
         width: 100%;
    }
     .pagination .page-item .page-link {
         width:40px;
         height:40px;
         line-height: 25px;
         font-size:16px;
    }
     section#page-not-found .errow-heading::before {
         line-height:45px;
         font-size:25px;
         top:2%;
         left:30px;
    }
     section#page-not-found .errow-heading img {
         z-index:2;
         position: relative;
         width:70px;
         height:auto;
    }
     section#page-not-found .errow-heading::after {
         line-height:45px;
         font-size:25px;
         top:2%;
         right:30px;
    }
     section#page-not-found {
         padding:100px 30px !important;
    }
     section#testimonials .testimonials-slider .item{
         float:left;
         text-align: center;
         padding:0px 30px 30px;
         background: #fff;
         position: relative;
    }
     .slider.index-2 .caption{
         width:auto;
         top:150px;
         left:5% !important;
         right:5% !important;
    }
     footer.index-2 .contact-info ul li .detail{
         width:57%;
    }
     footer .copyright > div > div .separator-pipesign{
         width: 15px;
         height: 1px;
         text-align: center;
         margin: 15px auto 10px;
         display: block;
    }
     .header-top.contact_info .follow_us_on, .header-top.contact_info .mail-call{
         border-top:none;
         text-align: center;
         padding:0px;
    }
     .header-top.contact_info .mail-call div, .header-top.contact_info .mail-call i::before {
         font-size:14px;
    }
     .slider.index-3 .caption {
         width:280px;
         top:110px;
    }
     .slider.index-3 .caption .display{
         font-size:35px;
         line-height:45px;
         margin:10px 0;
         font-family: 'Poppins', sans-serif;
         font-weight: 700;
    }
     .slider.index-2 .caption .display{
         font-size:40px;
         line-height:50px;
    }
     footer.index-2 .contact_info ul li .detail{
         margin-top:20px;
         width:100%;
    }
     section#counter .counter-column .count_icon i.flaticon-happiness::before{
         font-size: 55px;
    }
     section#counter .counter-column, section#counter.index-2 .counter-column{
         width:100%;
         margin-bottom:20px;
    }
     section#counter.index-2 .counter-column:last-child{
         margin-bottom:0px;
    }
     section#counter.index-2 .counter-column .count_icon{
         margin-right:10px;
    }
     section#counter.index-2 .counter-column .count_icon i.flaticon-happiness::before {
         font-size: 60px;
    }
     section#counter.index-2 .counter-column .count_icon i.flaticon-coffee-cup::before, section#counter.index-2 .counter-column .count_icon i.flaticon-building::before, section#counter.index-2 .counter-column .count_icon i.flaticon-like::before {
         font-size: 60px;
    }
     section#counter.index-2 .counter-column .count_icon i::before {
         line-height:normal;
    }
}
 
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,.owl-theme .owl-nav{text-align:center;-webkit-tap-highlight-color:transparent;}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev{width:50px; height: 50px; background: #fff; border:solid 1px #e4e4e4; color:#529e19; font-size: 32px;}
.owl-carousel .owl-nav button:hover{background:#529e19; border:solid 1px #fff; color:#fff;}
.owl-carousel .owl-nav button.owl-next:focus, .owl-carousel .owl-nav button.owl-prev:focus{ outline: none; }
.owl-carousel .owl-nav button.owl-prev{ left: -70px; position:absolute; top:40%; }
.owl-carousel .owl-nav button.owl-next{ right: -70px; position:absolute; top:40%; }

.owl-theme .owl-nav .disabled{opacity:.5;cursor:default}

.owl-theme .owl-nav.disabled+.owl-dots{margin-top:10px}
.owl-theme .owl-dots .owl-dot{display:inline-block;zoom:1}
.owl-theme .owl-dots .owl-dot span{width:10px;height:10px;margin:5px 7px;background:#D6D6D6;display:block;-webkit-backface-visibility:visible;transition:opacity .2s ease;border-radius:30px}
.owl-theme .owl-dots .owl-dot.active span,.owl-theme .owl-dots .owl-dot:hover span{background:#869791}



.main_header_upper{width:100%;border-bottom:1px solid #e9e9e9;background-color: #FF8000;height: 40px;}
.main_wraper{width:1186px;margin:0 auto;}
.email_section{width:180px;padding:0 0 0 20px;float:right;color:#FFFFFF;margin:10px 0px 10px 0px;font-size:16px;}
.phone_section{width:172px;padding:0 0 0 20px;float:right;color:#FFFFFF;margin:10px 0px 10px 0px;font-size:16px;}
.seowork_section{width:340px;padding:0px 0px 0px 10px;float:left;font-size:15px;}
.buyer_protection{width:170px;padding:0 0 0 30px;float:left;color:#777;line-height:40px;margin:15px 0px 10px 10px;font-size:16px;}
.social_icon{float:left;width:23%;margin:12px 0 0 0;}
.social_icon li{float:left;margin:0 5px;}
.social_i li i{top:-10px;} 
.social_icon li i{top:-10px;} 
.main_header_middle{width:100%;border-bottom:1px solid #e9e9e9;padding:15px 0 0 0;}
.logo{float:left;width:221px;padding:0 0 15px 0;height: 75px;}
.logo a img{ height: 70px; }
.navigation{float:right;width:367px;padding:25px 0 0 0;}
.navigation li{float:left;padding:0 10px;}
.navigation li a{color:#000;font-size:18px;}
.navigation li a:hover{color:#184AAC;}

.clearfix:after{content:"&nbsp;";font-size:0;display:block;height:0;clear:both;visibility:hidden;}
.clearfix{display:inline-block}
.clearfix{display:block}
* html .clearfix{height:1%;}
.social_i{display:none;}

.krown_social.header ul{margin-right:-12px;}
.krown_social.header li{display:block;color:#fff;height:22px;float:right;position:relative;margin:0;padding:0;border:none;}
.krown_social.header a{position:relative;width:25px;height:20px;color:#b2b2b2;font-size:10px;text-transform:uppercase;font-weight:bold;overflow:hidden;display:inline-block;}
.krown_social.header i{font-size:16px;line-height:39px;color:#FFFFFF;text-align:center;position:absolute;}
.krown_social.header span{display:inline-block;padding:0 26px!important;color:#608bb7 !important;font-weight:normal;font-size:12px;}
.krown_social.header li:last-child span{padding-right:0;}
.krown_social.header li:hover *{color:#006;}
.krown_social.default ul{margin:0;height:28px;}
.krown_social.default li{position:relative;cursor:pointer;text-align:center;display:inline-block;margin:0 10px 0 0;padding:0;border:none;width:25px;}
.krown_social.default i:before{font-size:20px;width:25px;color:#adadad;}
.krown_social.default a:hover i:before{color:#e54939;}
.krown_social.default a{display:block;height:25px;width:25px;}


@media only screen and (max-width:765px){
    .social_icon,.seowork_section,.buyer_protection{display: none;}
    .main_wraper{width: 100%;}
}